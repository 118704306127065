import { useEffect, useMemo } from 'react';
import { ResponsiveGrid } from '@adobe/aem-react-editable-components';
import { ApolloProvider } from '@apollo/client';
import { getDeployedEnvType, getNextMiApolloClient, useNextMiApolloClient } from '@marriott/mi-apollo-client-utils';
import { Layout, respGridUtil, prePageCall } from '@marriott/mi-headless-utils';
import {
  PageParamsContext,
  IMAGE_URL_DOMAIN,
  constants,
  PAGES,
  formatCityName,
  RECOMMENDED_RESULT_LIMIT,
  recommendationFilters,
  getSubDirectoryLocale,
} from '@marriott/mi-rnb-components';
import operationSignatures from '../../_constants/operationSignatures.json';
import Cookies from 'js-cookie';
import { searchRestaurantsByGeolocation, similarRestaurants } from '@marriott/mi-rnb-graphql';
import { isEmpty } from '@marriott/shared/mi-helper-utils';

const { NEXT_PUBLIC_AEM_PATH, NEXT_PUBLIC_DEFAULT_LANG = 'en-US' } = process.env;
const { ITEM_PATH_ROOT, ITEM_PATH_FOOTER, ITEM_PATH_HEADER } = constants;

const { ENV_DEV, REDIRECT_URL } = process.env;
const isDev = process.env['NODE_ENV'] === ENV_DEV;

const mockModel = async () => {
  if (isDev) {
    // nx-ignore-next-line
    const { cityPageModel } = await import('@marriott/mi-rnb-components/localModel');
    return cityPageModel;
  }
  return {};
};

const sessionID = Cookies.get('sessionID');
const currentTimestamp = Date.now();
const currentDateTimeStamp = new Date(currentTimestamp).getTime();
const { CITYPAGE } = PAGES;
// final data would have to come from 2 calls:
// 1. Page level call from AEM - that will populate the labels on components
// 2. Functional data for components like list of amenities and other related information
// import jsonContent from '../../mock/label-info.json';

export default function CityPage({
  model,
  deployedEnvType,
  isAuthorMode,
  outletData,
  cookies,
  recommendationData,
  requestUri,
  currentLocale,
}) {
  const MiApolloClient = useNextMiApolloClient(operationSignatures, deployedEnvType);

  const responsiveGridModel = respGridUtil(model?.cqItems?.root?.[':items']?.responsivegrid);
  const responsiveGridHeaderModel = respGridUtil(model?.cqItems?.root?.[':items']?.['header-responsivegrid']);
  const responsiveGridFooterModel = respGridUtil(model?.cqItems?.root?.[':items']?.['footer-responsivegrid']);
  const userIdToken = cookies.UserIdToken;

  const { metaNames, metaProperties, staticComponentClientLibsJS, staticComponentClientLibsCSS, gridCss, bgColor } =
    model;

  let { title, canonical, hrefLangList } = model;

  //  Updating the meta properties and title as per SEO requirements
  const metaProperty = metaProperties;

  const contextValue = useMemo(() => {
    return {
      userId: userIdToken,
      backgroundColor: bgColor,
      currentPage: CITYPAGE,
      recommendationData: recommendationData,
      currentLocale: currentLocale,
    };
  }, [bgColor, userIdToken, recommendationData, currentLocale]);

  // Format City Name
  const cityName = formatCityName(requestUri);

  // Using this to Prevent The Click event default action
  useEffect(() => {
    const headerAccBtn = document.getElementsByClassName('m-header__acnt t-global-nav-items font-nav-items');
    if (!headerAccBtn) return;
    const headerAccBtnMain = Array?.prototype?.filter?.call(headerAccBtn, element => element?.nodeName === 'LI');
    if (!headerAccBtnMain) return;

    const handlePreventDefault = (event: MouseEvent) => {
      event.preventDefault();
    };

    headerAccBtnMain[0]?.addEventListener('click', handlePreventDefault);
    return () => headerAccBtnMain[0]?.removeEventListener('click', handlePreventDefault);
  }, []);

  const updateMetaProperties = () => {
    const descriptionKey = metaNames?.find(item => item.name === 'description');

    // Title && OG title
    title = updateCityTitle(title);

    // Description & OG:DESCRIPTION
    let description = descriptionKey?.value;
    if (descriptionKey) {
      description = updateCityDescription(descriptionKey);
    }

    // Update Meta Names
    metaNames?.map(item => {
      if (item.name === 'description') {
        item.value = description;
      }
      if (item?.name === 'url') {
        const urlSplit = item?.value?.split('//');
        const hostUrl = urlSplit?.[1]?.split('/');
        item.value = urlSplit?.[0] + '//' + hostUrl?.[0] + '/' + hostUrl?.[1] + requestUri + '.mi';
      }
    });

    // Meta Properties
    metaProperty?.map(item => {
      // og:url
      if (item?.name === 'og:title') {
        item.value = title;
      }

      if (item?.name === 'og:description') {
        item.value = description;
      }
      if (item?.name === 'og:url') {
        const urlSplit = item?.value.split('//');
        const hostUrl = urlSplit?.[1]?.split('/');
        item.value = urlSplit?.[0] + '//' + hostUrl?.[0] + '/' + hostUrl?.[1] + requestUri + '.mi';
      }

      // og:image
      if (item?.name === 'og:image') {
        if (!item.value.includes(IMAGE_URL_DOMAIN)) {
          let imgUrl = '';
          const ogImage =
            outletData?.property?.restaurants?.edges[0]?.node?.media?.imageConnection?.edges[0]?.node?.imageUrls
              ?.classicHorizontal;
          if (ogImage) {
            imgUrl = IMAGE_URL_DOMAIN + ogImage;
            item.value = imgUrl;
          } else {
            imgUrl = IMAGE_URL_DOMAIN + item.value;
            item.value = imgUrl;
          }
        }
      }
    });
  };

  const updateCityTitle = title => {
    const filledTitle = title?.replace('[City]', cityName);
    return filledTitle;
  };

  const updateCityDescription = descriptionKey => {
    const descValue = descriptionKey.value;

    const filledDescription = descValue.replaceAll('[City]', cityName);
    return filledDescription;
  };

  const updateCanonicalValue = () => {
    const urlSplit = canonical?.split('//');
    const hostUrl = urlSplit?.[1]?.split('/');
    canonical = urlSplit?.[0] + '//' + hostUrl?.[0] + '/' + hostUrl?.[1] + requestUri + '.mi';
  };

  const updateHrefLangList = () => {
    hrefLangList = hrefLangList?.map(hrefLangItem => {
      return {
        ...hrefLangItem,
        href: hrefLangItem?.href?.replace('/dining/[City]', requestUri),
      };
    });
  };

  updateMetaProperties();
  updateCanonicalValue();
  updateHrefLangList();

  return (
    <ApolloProvider client={MiApolloClient}>
      <PageParamsContext.Provider value={contextValue}>
        <Layout
          metaNames={metaNames}
          metaProps={metaProperty}
          title={title}
          staticComponentClientLibsCSS={staticComponentClientLibsCSS}
          staticComponentClientLibsJS={staticComponentClientLibsJS}
          isAuthorMode={isAuthorMode}
          gridCss={gridCss}
          canonical={canonical}
          hreflangList={hrefLangList}
        >
          <section>
            {responsiveGridHeaderModel && (
              <ResponsiveGrid
                {...responsiveGridHeaderModel}
                model={responsiveGridHeaderModel}
                pagePath={NEXT_PUBLIC_AEM_PATH}
                itemPath={ITEM_PATH_HEADER}
              />
            )}

            {responsiveGridModel && (
              <ResponsiveGrid
                {...responsiveGridModel}
                model={responsiveGridModel}
                pagePath={NEXT_PUBLIC_AEM_PATH}
                itemPath={ITEM_PATH_ROOT}
              />
            )}
            {responsiveGridFooterModel && (
              <ResponsiveGrid
                {...responsiveGridFooterModel}
                model={responsiveGridFooterModel}
                pagePath={NEXT_PUBLIC_AEM_PATH}
                itemPath={ITEM_PATH_FOOTER}
              />
            )}
          </section>
        </Layout>
      </PageParamsContext.Provider>
    </ApolloProvider>
  );
}

//Any product specific page data apart from common data like session, datalayer
//can be returned from this function and the same is passed as props to the page Component
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rnbSpecificData = async (context: { [key: string]: any }) => {
  //any page specific code can be written here.
  const deployedEnvType = getDeployedEnvType();
  const resolvedUrl = context?.resolvedUrl;
  // Split the URL into an array of segments using "/" as the delimiter
  const urlSegments = resolvedUrl.split('/');
  // Insert "city-page" after the second segment (index 1)
  urlSegments.splice(2, 0, 'city-page');
  // Join the modified segments back into a URL string
  const uri = urlSegments.join('/');
  return {
    operationSignatures: operationSignatures,
    deployedEnvType,
    uri: uri,
  };
};

//Make pre page calls like session, datalayer, fetch model.json, producSpecifc data etc
//at server side and pass the data is passed as props to page component.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getServerSideProps = async context => {
  const response = prePageCall(rnbSpecificData, mockModel, false, true, true);
  const componentProps = await response(context).then(data => data);
  const deployedEnvType = getDeployedEnvType();
  const requestId = `${Date.now()}`;
  const MiApolloClient = getNextMiApolloClient(operationSignatures, deployedEnvType);
  const locale = componentProps?.props?.currentLocale?.split('_').join('-') || 'en-US';
  const subLocale = getSubDirectoryLocale(componentProps?.props?.currentLocale?.split('_').join('-') || 'en-US');

  if (
    componentProps?.props?.model &&
    !isEmpty(componentProps?.props?.model?.cqPath) &&
    !isEmpty(componentProps?.props?.model?.cqItems) &&
    !isEmpty(componentProps?.props?.model?.cqItems?.root[':items']?.responsivegrid[':items'])
  ) {
    const pageDomain =
      'https://' +
      componentProps?.props?.headersData['x-host'] +
      '/' +
      locale +
      '/' +
      componentProps?.props?.requestUri?.split('/')[1];

    const recommendedProps =
      componentProps?.props?.model?.cqItems?.root[':items']?.responsivegrid[':items']?.recommendation;

    const query =
      recommendedProps?.recommendationTypes === 'geosearch' ? searchRestaurantsByGeolocation : similarRestaurants;

    const geoSearchVariable = {
      search: {
        latitude: recommendedProps?.latitude,
        longitude: recommendedProps?.longitude,
      },
      limit: RECOMMENDED_RESULT_LIMIT,
    };

    const itemSearchVariable = {
      input: {
        restaurantId: recommendedProps?.outletId?.toString(),
        filters: recommendationFilters(recommendedProps),
      },
      limit: RECOMMENDED_RESULT_LIMIT,
    };

    const queryVariable =
      recommendedProps?.recommendationTypes === 'geosearch' ? geoSearchVariable : itemSearchVariable;

    try {
      const { data } = await MiApolloClient.query({
        query: query,
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        variables: queryVariable,
        context: {
          headers: {
            // Any header, including x-request-id, can be passed in args with query.
            // If you don't pass it in the authLink will generate a random ID.
            'x-request-id': requestId,
            'accept-language': locale,
            'x-b3-traceId': requestId !== '' ? requestId : `${currentDateTimeStamp}`,
            'x-b3-spanId': requestId !== '' ? requestId : `${currentDateTimeStamp}`,
            'correlation-id': `${sessionID ?? sessionID ?? 'fallback-token'} - ${currentDateTimeStamp}`,
          },
        },
      });

      if (data) {
        const recommendationResponse =
          recommendedProps?.recommendationTypes === 'geosearch'
            ? data?.searchRestaurantsByGeolocation?.edges
            : data?.searchRecommendations?.similarRestaurants?.edges;

        return {
          props: {
            deployedEnvType: componentProps?.props?.apolloEnvVars?.DEPLOYED_ENV_TYPE,
            ...componentProps.props,
            recommendationData: recommendationResponse || [],
            showRichResults: true,
            pageDomain: pageDomain,
            recommendedFallBackImage: recommendedProps?.dynamicMedia?.assetPath,
          },
        };
      }
    } catch (err) {
      return {
        props: {
          deployedEnvType: componentProps?.props?.apolloEnvVars?.DEPLOYED_ENV_TYPE,
          ...componentProps.props,
          recommendationData: [],
          showRichResults: false,
        },
      };
    }
  } else {
    return {
      redirect: {
        destination: `/${subLocale}${REDIRECT_URL}`,
        permanent: false,
      },
    };
  }
};
